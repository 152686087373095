#header {
  display: flex;
  justify-content: center;
  text-align: center;
  font-family: 
    Helvetica Neue Light,
    Helvetica Neue, 
    Helvetica, Arial, 
    Lucida Grande, 
    sans-serif;
    padding-bottom: -10px;
    margin-bottom: -10px;
}


#shovel {
  margin-top: 15px;
}


#plant-error {
  color: #666;
  height: 0.9em;
  font-size: 0.75em;
  display: flex;
  justify-content: center;
  align-items: center;
  /* font-style: italic; */
  font-family: 
    Helvetica Neue Light,
    Helvetica Neue, 
    Helvetica, Arial, 
    Lucida Grande, 
    sans-serif;
}


#garden {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  /* pointer-events: none; */
}


#guidelines, #contribs, #error {
  display: flex;
  justify-content: center;
  text-align: center;
  font-family: 
    Helvetica Neue Light,
    Helvetica Neue, 
    Helvetica, Arial, 
    Lucida Grande, 
    sans-serif;
    padding: 30px;
}


#semi {
  display: flex;
  justify-content: center;
  text-align: center;
  font-family: 
    Helvetica Neue Light,
    Helvetica Neue, 
    Helvetica, Arial, 
    Lucida Grande, 
    sans-serif;
    padding: 30px;
}


#link {
  display: none;
}


#version {
  position: absolute;
  left: 2px;
  top: 2px;
  color: #BBB;
  font-size: 0.7em;
  font-family: 
    Helvetica Neue Light,
    Helvetica Neue, 
    Helvetica, Arial, 
    Lucida Grande, 
    sans-serif;
}


.lb-area {
  background: none;
  box-sizing: border-box;
  display: block;
  margin: auto;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  padding: 10px;  
  resize: none;
  overflow: auto;
	height: auto;
  font-size: 20px;
  text-align: center;
  line-height: 24px;
  border: none;
  font-family: 
    HelveticaNeue-Light, 
    Helvetica Neue Light,
    Helvetica Neue, 
    Helvetica, Arial, 
    Lucida Grande, 
    sans-serif;
  /* box-shadow: 0px 4px 10px -8px black; */
}	


.lb-area::-webkit-scrollbar {
  display: none;
}


.lb-area::placeholder {
	color: gainsboro;
}


.lb-area:focus {
  outline: none;
}


h1 {
  color: #2c3e50;
  margin: 0;
  padding: 15px 15px;
  font-weight: bold;
}